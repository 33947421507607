@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

.layout-topbar-left{
    background-color: #0c5235 !important;
}
.layout-root-menuitem {
    ul li :hover{
        background-color: #0c52354a !important;
    }
    .router-link-active{
        background-color: #0c52354a !important;
    }
    .router-link-active *{
        color: #0c5235 !important;
    }
}

.p-button {
    background-color: #0c5235 !important;
    border-color: #0c5235 !important;
    color: white !important;
    &:hover {
        background-color: #0c5235 !important;
        border-color: #0c5235 !important;
        color: white !important;
    }
}
.p-tabview-nav-link{
    color: #0c5235 !important;
    &:hover{
        color: #0c5235 !important;
    }
    background-color: #0e563809 !important;
}
.p-tabview-selected{
    color: white !important;
    background-color: #0c523530 !important;
}
.p-tabview-ink-bar{
    background-color: #0c5235 !important;
}

.white-icon .pi-calendar {
    color: white !important;
}

.p-progressbar{
    background-color: #0c52351f !important;
}
.p-progressbar-value-animate{
    background-color: #0c5235 !important;
}