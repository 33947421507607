/* styles.css */

/* Styles for success message */
.success-message {
    color: black;
    margin: 10px;
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }

  .result-message{
    margin: 20px;
  }
  
  /* Styles for the reader div */
  #reader {
    border: 2px solid #ccc;
    padding: 10px;
    width: 500px;
    height: 500px;    
  }
  
  /* Styles for the button */
  .button-mark-attendance {
    background-color:#085129;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease; /* Adding a smooth transition effect */
}

.button-mark-attendance:hover {
    background-color: #0c5235cf; /* Change the color for the hover effect */
}
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Set the height of the container to the full viewport height */
  }

  .camera-container{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }